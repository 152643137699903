import styled from "styled-components";

export const Separator = styled.div`
    height: 1px;
    background: linear-gradient(
        90deg, rgba(230,230,250,0) 0%,
        rgba(230,230,250,0.10) 25%,
        rgba(230,230,250,0.5) 38%,
        rgba(230,230,250,1) 50%,
        rgba(230,230,250,0.5) 63%,
        rgba(230,230,250,0.10) 75%,
        rgba(230,230,250,0) 100%
    );
    margin-bottom: 3vh;
`

export const SmallSeparator = styled.div`
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(230,230,250,0) 0%,
        rgba(230,230,250,0) 34%,
        rgba(230,230,250,0.1) 38%,
        rgba(230,230,250,0.25) 44%,
        rgba(230,230,250,1) 50%,
        rgba(230,230,250,0.25) 56%,
        rgba(230,230,250,0.1) 62%,
        rgba(230,230,250,0) 66%,
        rgba(230,230,250,0) 100%
    );
    margin-bottom: 3vh;
`

export const SmallSeparatorRight = styled.div`
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(230,230,250,1) 9%,
        rgba(230,230,250,0.5) 17%,
        rgba(230,230,250,0.1) 25%,
        rgba(230,230,250,0) 32%,
        rgba(230,230,250,0) 100%
    );
    margin-top: 1vh;
    margin-left: 1vw;
`

export const SmallSeparatorLeft = styled.div`
    height: 1px;
    background: linear-gradient(
        270deg, rgba(230,230,250,1) 9%,
        rgba(230,230,250,0.5) 17%,
        rgba(230,230,250,0.1) 25%,
        rgba(230,230,250,0) 32%,
        rgba(230,230,250,0) 100%
    );
    margin-top: 1vh;
    margin-right: 1vw;
`