import React from "react";
import '../../App.css';
import ProfilBody from "../content/ProfilBody";

export default function Profil() {
    return (
        <>
            <ProfilBody />
        </>
    );
}