import Modal from "react-modal";
import React, {useState} from "react";
import '../../css/Button.css';
import '../../css/Modal.css';
import {useWebsiteContext} from "../../../WebsiteDataContext";
import {Col, Row} from "react-bootstrap";
import emailjs from "emailjs-com";

export default function ModalContact({modalOpen, toggle}) {
    const {language, emailTemplate, changeEmailTemplate} = useWebsiteContext()

    const [toggleSucces, setToggleSucces] = useState(false)
    const toggleSuccesModal = () => {
        setToggleSucces((prevState) => !prevState)
    }

    const [toggleError, setToggleError] = useState(false)

    const sendMail = () => {
        emailjs.init('user_01PtdzIXeu6KrKoTdUft1')
        emailjs.send('gmail', 'portfolio-template', emailTemplate)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
    }

    const editForm = (e, key) => {
        e.preventDefault()
        changeEmailTemplate(key, e.target.value)
    }

    const closeFromSucces = () => {
        toggleSuccesModal()
        toggle()
    }

    const validateData = () => {
        if (
            emailTemplate.poste === "" ||
            emailTemplate.salaire === "" ||
            emailTemplate.periode === "" ||
            emailTemplate.nom === "" ||
            emailTemplate.telephone === "" ||
            emailTemplate.courriel === "" ||
            emailTemplate.entreprise === ""
        ) {
            setToggleError(true)
        } else {
            setToggleError(false)
            sendMail()
            toggleSuccesModal()
        }
    }

    return (
        <Modal isOpen={modalOpen}
               onRequestClose={() => {
                   !toggleSucces && modalOpen ? toggle() : closeFromSucces()
               }}
               contentLabel={"Contact modal"}
               className={"contact-modal-content"}
               overlayClassName={"contact-modal-overlay"}
               ariaHideApp={false}
        >
            { !toggleSucces &&
                <>
                    <div className={"modal-contact-header text-wrapper-center"}>
                        <Row>
                            <Col className={"col-auto"}/>
                            <Col className={"col-md-10"}>
                                <h3 className="col-md-12">{language.modalContact.titre}</h3>
                            </Col>
                            <Col className={"col-auto"}>
                                <i className="fa fa-times clickable fa-2x" aria-hidden="true" onClick={toggle}/>
                            </Col>
                        </Row>
                        <div className="modal-contact-subtitle">{language.modalContact.sousTitre}</div>
                    </div>

                    <div className="col-md-12">
                        {language.global.contact}
                        <Row>
                            <Col className={"col-md-8"}>
                                <input className="form-control" type="text" name="name"
                                       placeholder={language.modalContact.input.nom}
                                       onChange={(e => editForm(e, 'nom'))}
                                       value={emailTemplate.nom} required/>
                                <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                            </Col>
                            <Col className={"col-md-4"}>
                                <input className="form-control" type="text" name="telephone"
                                       onChange={(e => editForm(e, 'telephone'))}
                                       placeholder={language.global.telephone} value={emailTemplate.telephone} required/>
                                <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                            </Col>
                        </Row>
                    </div>

                    <div className="col-md-12 mt-1">
                        <Row>
                            <Col className={"col-md-8"}>
                                <input className="form-control" type="email" name="email"
                                       onChange={(e => editForm(e, 'courriel'))}
                                       placeholder={language.modalContact.input.mail} value={emailTemplate.courriel} required/>
                                <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                            </Col>
                            <Col className={"col-md-4"}>
                                <select className="form-select" value={emailTemplate.poste} required
                                        onChange={(e => editForm(e, 'poste'))}
                                >
                                    <option defaultValue disabled value="">Position</option>
                                    {language.modalContact.select.position.map(position => {
                                        return (
                                            <option value={position.toLowerCase()} key={position}>
                                                {position}
                                            </option>
                                        )
                                    })}
                                </select>
                                <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                            </Col>
                        </Row>
                    </div>

                    <div className="col-md-12 mt-3">
                        <input className="form-control" type="text" name="entreprise"
                               onChange={(e => editForm(e, 'entreprise'))}
                               placeholder={language.modalContact.input.entreprise} value={emailTemplate.entreprise} required/>
                        <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <label htmlFor="salary"
                               style={{marginRight: '1vw'}}>{language.global.salaire}
                        </label>
                        <Row>
                            <Col className={"cpl-md-8"}>
                                <input className="form-control" type="text" name="salary" placeholder="0,00$"
                                       onChange={(e => editForm(e, 'salaire'))}
                                       value={emailTemplate.salaire} required/>
                                <div className="invalid-feedback mv-up">{language.modalContact.champInvalide}</div>
                            </Col>
                            <Col className={"col-md-4"}>
                                <select className="form-select" value={emailTemplate.periode}
                                        onChange={(e => editForm(e, 'periode'))}
                                >
                                    {language.modalContact.select.salaire.map(periode => {
                                        return (
                                            <option value={periode.toLowerCase()} key={periode}
                                                    defaultValue={periode === "Heure"}
                                            >
                                                {periode}
                                            </option>
                                        )
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </div>

                    <div className="col-md-12 mt-3">
                        {language.modalContact.facultatif}
                        <input className="form-control" type="text" name="subject"
                               value={emailTemplate.sujet} placeholder="Subject" required
                               onChange={(e => editForm(e, 'sujet'))}
                        />
                        <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                        <textarea className="form-control contact-message-area mt-1" name="message"
                                  onChange={(e => editForm(e, 'message'))}
                                  value={emailTemplate.message} placeholder="Message"/>
                        <div className="invalid-feedback">{language.modalContact.champInvalide}</div>
                    </div>
                    <div className="d-flex">
                        <div className={"col-md-2 mt-3 mb-3 btn__shinny-small"} onClick={(e) => {
                            e.preventDefault()
                            validateData()
                        }}>
                            {language.modalContact.send}
                        </div>
                        {toggleError && 
                            <div className="mt-2 ms-3 mb-2 text-warning">
                                {language.modalContact.error}
                            </div>
                        }
                    </div>
                </>
            }
            {toggleSucces &&
                <div className={"modal-contact-header text-wrapper-center"}>
                    <Row>
                        <Col className={"col-auto"}/>
                        <Col className={"col-md-10"}>
                            <h1 className="col-md-12 text-success">
                                {language.modalContact.success}
                            </h1>
                        </Col>
                        <Col className={"col-auto"}>
                            <i className="fa fa-times clickable fa-2x" aria-hidden="true" onClick={toggle}/>
                        </Col>
                    </Row>
                </div>
            }
        </Modal>
    )
}