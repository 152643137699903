import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import ketchup from "./ketchup.json";
import chutneyOignonsRouges from "./chutneyOignonRouges.json";

export default function Condiments() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Condiments</h2>
            </div>
            <RecettesCards {...ketchup}/>
            <RecettesCards {...chutneyOignonsRouges}/>
        </div>
    );
}