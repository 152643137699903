import {useWebsiteContext} from "../../../WebsiteDataContext";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {Separator, SmallSeparatorLeft, SmallSeparatorRight} from "../../styled/Separator";
import styled from "styled-components";

export default function ExperienceProfessionnelle() {

    const {language} = useWebsiteContext()

    return (
        <div>
            <div className="text-margin-small text-wrapper-center">
                <Row>
                    <Col>
                        <SmallSeparatorLeft/>
                    </Col>
                    <Col xs={5} sm={4} md={4} lg={5} xl={4}>
                        <h2 style={{marginBottom: '-3vh'}}>{language.profil.experiencePro}</h2><br/>
                    </Col>
                    <Col>
                        <SmallSeparatorRight/>
                    </Col>
                </Row>
            </div>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.programmeur} (fullstack)
                    <TinyText>Websisoft, Montréal</TinyText>
                </Col>
                <Col xs={2} className="profile-columns-dates" style={{marginTop: '-0.05vh'}}>
                    2021
                    <TinyText>{language.profil.stage}</TinyText>
                </Col>
            </Row>
            <Row className="text-margin-small text-wrapper-left">
                <Col>
                    {language.profil.competences.webisoft.map(item => {
                        return (<li key={item} style={{marginLeft: '2vw'}}>{item}</li>)
                    })}
                </Col>
            </Row>
            <Separator/>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.technicienReseau}
                    <TinyText>Médiavore, Laval</TinyText>
                </Col>
                <Col xs="2" className="profile-columns-dates" style={{marginTop: '-0.05vh'}}>
                    2020
                    <TinyText>{language.profil.stage}</TinyText>
                </Col>
            </Row>

            <Row className="text-margin-small text-wrapper-left">
                <Col>
                    {language.profil.competences.mediavore.map(item => {
                        return (<li key={item} style={{marginLeft: '2vw'}}>{item}</li>)
                    })}
                </Col>
            </Row>
            <Separator/>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.technicienReseau}
                    <TinyText>Cogéco, Montréal</TinyText>
                </Col>
                <Col xs="2" className="profile-columns-dates" style={{marginTop: '-0.05vh'}}>
                    2013
                    <TinyText>{language.profil.stage}</TinyText>
                </Col>
            </Row>
            <Row className="text-margin-medium text-wrapper-left">
                <Col>
                    {language.profil.competences.cogeco.map(item => {
                        return (<li key={item} style={{marginLeft: '2vw'}}>{item}</li>)
                    })}
                </Col>
            </Row>
        </div>
    )
}

const TinyText = styled.div`
    font-size: 18px;
    @media screen and (max-width: 1250px) {
        font-size: 14px;
    }
`