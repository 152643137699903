import React from "react";
import { Link } from "react-router-dom";

function Cards(props) {
    return (
      <li className='cards-body'>
          <Link className='cards-link' to={props.path}>
              <figure className='cards-label' data-category={props.label}>
                  <img src={props.src} alt={props.alt} className="cards-img"/>
              </figure>
              <div className='cards-description-body'>
                  <h5 className='cards-description-text'>{props.text}</h5>
              </div>
          </Link>
      </li>
    );
}

export default Cards;