import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import cremeGlaceeNoisette from "./cremeGlaceeNoisette.json";
import veganBlueberryLimeCheesecake from "./veganBlueberryLimeCheesecake.json";
import helltakerSzarlotka from "./helltakerSzarlotka.json";
import sorbetAuxFramboises from "./sorbetFramboise.json";
import sorbetAuxPetitsFruits from "./sorbetPetitsFruits.json";

export default function Desserts() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Dessert</h2>
            </div>
            <RecettesCards {...cremeGlaceeNoisette}/>
            <RecettesCards {...veganBlueberryLimeCheesecake}/>
            <RecettesCards {...helltakerSzarlotka}/>
            <RecettesCards {...sorbetAuxFramboises}/>
            <RecettesCards {...sorbetAuxPetitsFruits}/>
        </div>
    );
}