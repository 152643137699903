import React from "react";
import '../css/Body.css';
import {useWebsiteContext} from "../../WebsiteDataContext";
import ProfilFormation from "./components/ProfilFormation";
import ExperienceProfessionnelle from "./components/ExperienceProfessionnelle";
import {SmallSeparator} from "../styled/Separator";
import Competences from "./components/Competences";

function ProfilBody() {
    const {language} = useWebsiteContext()
    return (
        <div className='body'>
            <div className="container-body">
            <div className={"text-wrapper-center text-margin-small"}>
                    <h1>{language.global.profil}</h1>
                    <SmallSeparator/>
                </div>
                <div className={"text-margin-small"}>
                    <div className={"text-margin-small text-wrapper-center"}>
                        <h2 className={"text-margin-small"}>
                            {language.profil.programmeur}<br/>
                            {language.profil.administrateurReseau}
                        </h2>
                    </div>
                    <div className={"text-margin-xl"}>
                        <ProfilFormation/>
                    </div>
                    <div className={"text-margin-small"}>
                        <ExperienceProfessionnelle/>
                    </div>

                    <div className={"text-margin-large"}>
                        <Competences/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilBody;