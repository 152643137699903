import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import vegeBurgerHaricotsNoirs from "./vegeBurgerHaricotsNoirs.json";
import gnocchisPatateDouce from "./gnocchisPatateDouce.json";
import potageCourgeThai from "./potageCourgeThailandaise.json";
import raviolisChampignons from "./raviolisChampignons.json";
import truiteCremeCitronMarjolaine from "./truiteCremeCitronMarjolaine.json";
import mapoTofu from "./mapoTofu.json";
import pateLegumesCrevettes from "./patesLegumesCrevettes.json";
import spaghettiAllaCarbonara from "./spaghettiAllaCarbonara.json";
import patesAuxPetonclesDePleurottesKing from "./patesAuxPetonclesDePleurottesKing.json";

export default function Repas() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Plat principal</h2>
            </div>
            <RecettesCards {...vegeBurgerHaricotsNoirs}/>
            <RecettesCards {...gnocchisPatateDouce}/>
            <RecettesCards {...potageCourgeThai}/>
            <RecettesCards {...raviolisChampignons}/>
            <RecettesCards {...truiteCremeCitronMarjolaine}/>
            <RecettesCards {...mapoTofu}/>
            <RecettesCards {...pateLegumesCrevettes}/>
            <RecettesCards {...spaghettiAllaCarbonara}/>
            <RecettesCards {...patesAuxPetonclesDePleurottesKing}/>
        </div>
    );
}