import React from 'react';
import {Carousel, CarouselItem} from "react-bootstrap";
import {useWebsiteContext} from "../../../WebsiteDataContext";

function CarouselGalerie() {

    const unavailable = "/images/imagenotavailable.png"
    const {language, carousel} = useWebsiteContext()

    return (
        <Carousel style={{width: "75%", height: "75vh"}}>
            {carousel && carousel.map(
                item => {
                    return (
                        <CarouselItem key={item}>
                            <img className="d-block w-100"
                                 src={item}
                                 alt={language.galerie.imageNotFound}
                                 onError={e => {
                                     e.target.src = unavailable
                                 }}
                                 style={{width: "75%", height: "70vh"}}
                            />
                        </CarouselItem>
                    )
                }
            )}
        </Carousel>
    )
}


export default CarouselGalerie;
