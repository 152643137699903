import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import pangrattato from "./pangrattato.json";
import vinaigretteCibouletteErable from "./vinaigretteCibouletteErable.json"

export default function Assaisonnements() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Assaisonnements</h2>
            </div>
            <RecettesCards {...pangrattato}/>
            <RecettesCards {...vinaigretteCibouletteErable}/>
        </div>
    );
}