import {Col, Row} from "react-bootstrap";
import React from "react";
import {useWebsiteContext} from "../../../WebsiteDataContext";
import styled from "styled-components";
import {Separator, SmallSeparatorLeft, SmallSeparatorRight} from "../../styled/Separator";

export default function ProfilFormation() {

    const {language} = useWebsiteContext()

    return (
        <div>
            <div className="text-margin-small text-wrapper-center">
                <Row>
                    <Col>
                        <SmallSeparatorLeft className="formation-title-left"/>
                    </Col>
                    <Col xs={5} lg={3} xl={2}>
                        <h2>{language.profil.formation}</h2>
                    </Col>
                    <Col>
                        <SmallSeparatorRight className="formation-title-right"/>
                    </Col>
                </Row>
                <h4>{language.profil.auxEtudes}</h4>
            </div>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.baccalaureatTechno}
                    <TinyText>École de technologie supérieur, Montréal</TinyText>
                </Col>
                <Col xs="2" className="profile-columns-dates">
                    {language.global.depuis} 2020
                </Col>
            </Row>
            <Separator/>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.dec}
                    <TinyText>Collège de Montmorency, Laval</TinyText>
                </Col>
                <Col xs="2" className="profile-columns-dates">
                    2020
                </Col>
            </Row>
            <Separator/>
            <Row className="text-margin-small text-wrapper-left profile-rows">
                <Col>
                    {language.profil.dep}
                    <TinyText>Centre de formation professionnel des Riverains, Repentigny</TinyText>
                </Col>
                <Col xs="2" className="profile-columns-dates">
                    2014
                </Col>
            </Row>
        </div>
    )
}

const TinyText = styled.div`
    font-size: 18px;
    @media screen and (max-width: 1250px) {
        font-size: 14px;
    }
`