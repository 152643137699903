import React from "react";
import Cards from "../utils/Cards";
import '../css/Body.css'
import {useWebsiteContext} from "../../WebsiteDataContext";
import {SmallSeparator} from "../styled/Separator";

function HomeBody() {
    const {language} = useWebsiteContext()

    return (
        <div className='body'>
            <div className="container-body">
                <div className={'text-wrapper-center'}>
                    <h1>
                        {language.homeBody.title}
                    </h1>
                    <h4 className="en-developpement mt-5">
                        {language.global.enDeveloppement}
                    </h4>
                </div>
                <SmallSeparator/>
                <div className='cards-container'>
                    <div className='cards-wrapper'>
                        <ul className='cards-items'>
                            <Cards src='/images/edigo.png'
                                text={language.homeBody.cards.card.edigo}
                                label='Edigo'
                                path='/'
                                alt='Edigo'
                            />
                            <Cards src='/images/web-portfolio-reactjs.png'
                                text={language.homeBody.cards.card.portfolio}
                                label='Portfolio ReactJS'
                                path='/'
                                alt='Portfolio ReactJS'
                            />
                            <Cards src='/images/2021.png'
                                text={language.homeBody.cards.card.catDungeon}
                                label='Cat Dungeon'
                                path='/'
                                alt='Cat Dungeon'
                            />
                        </ul>
                        <ul className='cards-items'>
                            <Cards src='/images/photoshopdupauvre.jpg'
                                text={language.homeBody.cards.card.photoshopDuPauvre}
                                label={language.homeBody.cards.label.photoshopDuPauvre}
                                path='/'
                                alt='Photoshop Du Pauvre'
                            />
                            <Cards src='/images/simulationusine.png'
                                text={language.homeBody.cards.card.usines}
                                label={language.homeBody.cards.label.usines}
                                path='/'
                                alt='Usines'
                            />
                            <Cards src='/images/dico.png'
                                text={language.homeBody.cards.card.dictionnaire}
                                label={language.homeBody.cards.label.dictionnaire}
                                path='/'
                                alt='Dictionnaire'
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeBody;