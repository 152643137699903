import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import mojito from "./mojito.json";

export default function Breuvages() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Breuvages</h2>
            </div>
            <RecettesCards {...mojito}/>
        </div>
    );
}