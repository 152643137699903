import React from "react";
import '../../App.css';
import AproposBody from "../content/AproposBody";

export default function Apropos() {
    return (
        <>
            <AproposBody/>
        </>
    );
}
