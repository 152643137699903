import React, {useState} from "react";
import '../css/Body.css';
import '../css/Button.css';
import {useWebsiteContext} from "../../WebsiteDataContext";
import {SmallSeparator} from "../styled/Separator";
import ModalContact from "./components/ModalContact";
import CurriculumVitae from "./components/CurriculumVitae";

function ContactBody() {
    const {language} = useWebsiteContext()
    const [modalOpen, setModalOpen] = useState(false)
    const [phoneOpen, setPhoneOpen] = useState(false)

    const toggleModal = () => {
        if (!modalOpen) {
            document.getElementsByTagName('*')[0].style.overflow = 'hidden'
        } else {
            document.getElementsByTagName('*')[0].style.overflow = 'auto'
        }
        setModalOpen((prevState) => !prevState)
    }

    const togglePhone = () => {
        setPhoneOpen((prevState) => !prevState)
    }

    return (
        <div className='body'>
            <div className={"contact-container container-body"}>
                <div className={"text-wrapper-center mb-5"}>
                    <h1>{language.global.contact}</h1>
                    <SmallSeparator/>
                </div>
                <div className={'mb-5'}>
                    <div className={'d-flex justify-content-center'}>
                        <div className={'btn__shinny text-wrapper-center me-3'} onClick={toggleModal}>
                            {language.contact.sendMail}
                        </div>
                        <div className={'btn__shinny text-wrapper-center me-3'} onClick={togglePhone}>
                            {language.global.telephone}
                        </div>
                        <div className='btn__shinny text-wrapper-center'>
                            <a href="https://www.linkedin.com/in/jp-mongeau/"
                               target="_blank"
                               rel="noreferrer"
                               style={{padding: '1vw', borderRadius: '5px'}}
                            >
                                LinkedIn <i className="fab fa-linkedin"/>
                            </a>
                        </div>
                    </div>
                </div>
                <ModalContact modalOpen={modalOpen}
                              toggle={toggleModal}
                />

                {phoneOpen &&
                <>
                    <div className={"contact-phone-container mb-5"}>
                        <div className={"p-2 m-2"}>
                            <h4><i className="fas fa-phone m-3"/> +1 (514) 679-8370</h4>
                            <p>{language.contact.appel}</p>
                            <ul>
                                <li>{language.contact.salaire}</li>
                                <li>{language.contact.taches}</li>
                                <li>{language.contact.type}</li>
                                <li>{language.contact.entreprise}</li>
                            </ul>
                            <br/>
                            <p>{language.contact.pasInteresse}</p>
                            <p>{language.contact.essayez}</p>
                            <p>{language.contact.interret}</p>
                            <p>{language.contact.devopsOuFinancement}</p>
                        </div>
                    </div>
                </>
                }
            </div>
            <div className="curriculum-container">
                <CurriculumVitae lang={language.language}/>
            </div>
        </div>
    );
}

export default ContactBody;