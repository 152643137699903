import React from "react";
import './App.css';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Footer from './components/Footer';
import Recettes from './components/Recettes';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {WebsiteDataProvider} from "./WebsiteDataContext";

export default function App() {
    return (
        <WebsiteDataProvider>
            <div>
                <Router>
                    <Header/>
                    <Route path='/' exact component={Hero}/>
                    <Route path='/recettes' exact component={Recettes}/>
                    <Footer/>
                </Router>
            </div>
        </WebsiteDataProvider>
    );
}