import React from "react";
import Home from './web-pages/Home';
import Profil from './web-pages/Profil';
import Galerie from './web-pages/Galerie';
import Contact from './web-pages/Contact';
import Apropos from './web-pages/Apropos';
import '../App.css';
import './css/Body.css';

export default function App() {
    return (
        <>
            <div className="home"><Home/></div>
            <div className="profil"><Profil/></div>
            <div className="galerie"><Galerie/></div>
            <div className="contact"><Contact/></div>
            <div className="apropos"><Apropos/></div>
        </>
    );
}