import React, {useState} from "react";
import './css/Header.css';
import {Link} from "react-scroll";
import {useWebsiteContext} from "../WebsiteDataContext";

export default function Header() {

    const [click, setClick] = useState(false);
    const {
        useFrench,
        useEnglish,
        language
    } = useWebsiteContext();

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const lang = language.language === 'french' ? useEnglish : useFrench

    return (
        <nav className='header-wrapper'>
            <li className='header-body'>

                <Link to='home' className='header-logo' onClick={closeMobileMenu}>
                    <i className="fas fa-user-circle"/> {language.global.portfolio}
                </Link>

                <div className='header-nom'>
                    <p>Jean-Philippe Mongeau</p>
                </div>

                <div className='header-mobile-menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>

                <ul className={click ? 'header-menu active' : 'header-menu'}>
                    <li className='header-item'>
                        <Link className='header-links' activeClass="active" to="home" spy={true} smooth={true}
                              onClick={closeMobileMenu}>
                            {language.global.projets}
                        </Link>
                    </li>
                    <li className='header-item'>
                        <Link className='header-links' to="profil" spy={true} smooth={true} onClick={closeMobileMenu}>
                            {language.global.profil}
                        </Link>
                    </li>
                    <li className='header-item'>
                        <Link className='header-links' to="galerie" spy={true} smooth={true} onClick={closeMobileMenu}>
                            {language.global.galerie}
                        </Link>
                    </li>
                    <li className='header-item'>
                        <Link className='header-links' to="contact" spy={true} smooth={true} onClick={closeMobileMenu}>
                            {language.global.contact}
                        </Link>
                    </li>
                    <li className='header-item'>
                        <Link className='header-links' to="apropos" spy={true} smooth={true} onClick={closeMobileMenu}>
                            {language.global.aPropos}
                        </Link>
                    </li>
                    <li className={"header-item btn__shinny-header header-lang-btn"} onClick={() => {
                        closeMobileMenu()
                        lang()
                    }}>
                        {language.language === 'french' ? "English" : "Français"}
                    </li>
                </ul>
                <div className='header-mobile-menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>
            </li>
        </nav>
    );
}