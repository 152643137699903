import {Cards} from '../styled/Cards';
import {useWebsiteContext} from "../../WebsiteDataContext";
import {useEffect} from "react";

export default function SpecialCards({
                                         id,
                                         title,
                                         titleLink,
                                         intro,
                                         introLink,
                                         cardInfo,
                                         imgList,
                                         readMore,
                                         date,
                                         tag,
                                         tagLinks,
                                         backgroundUrl,
                                         toggleOn,
                                         toggle
                                     }) {

    const {setCarousel} = useWebsiteContext()

    const getTags = () => {
        let list = []
        for (let i = 0; i < tag.length; i++) {
            if (tagLinks) {
                if (tagLinks[i]) {
                    tag[i + 1] ?
                        list.push(<div key={tag[i]}><a key={tag[i]} href={tagLinks[i]}>{`${tag[i]}`}</a>, </div>) :
                        list.push(<a key={tag[i]} href={tagLinks[i]}>{`${tag[i]}`}</a>)
                } else {
                    tag[i + 1] ?
                        list.push(`${tag[i]}, `) :
                        list.push(`${tag[i]}`)
                }
            } else {
                tag[i + 1] ?
                    list.push(`${tag[i]}, `) :
                    list.push(`${tag[i]}`)
            }
        }
        return list
    }

    const background = backgroundUrl ?
        {background: `url(${backgroundUrl}) center no-repeat`} :
        {background: 'url(https://unsplash.it/600/800?image=1061) center no-repeat'}


    const changeCarousel = () => {
        imgList.length === 0 ? setCarousel(["/images/imagenotavailable.png"]) : setCarousel(imgList)
        toggleOn()
        const view = document.getElementById("carousel-gallery")
        if (view) {
            view.scrollIntoView({behavior: 'smooth'})
        }
    }

    useEffect(() => {
        changeCarousel()
        toggle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [])

    return (
        <Cards key={`card-${id}`}>
            {window.innerWidth <= 500 &&
                <div className="blog-card" style={background} onClick={() => {
                    changeCarousel()
                }}>
                    <div className={"blog-card__container"}>
                        <div className="title-content">
                            {title ?
                                <h3>
                                    <a href={titleLink}>
                                        {title}
                                    </a>
                                </h3>
                                : null}
                            {intro ? <div className="intro"><a href={introLink}>{intro}</a></div> : null}
                        </div>
                        <div className="utility-info">
                            <ul className="utility-list" key={`utility-list-${id}`}>
                                {
                                    date ?
                                        <li key={`date-list-${id}`}>
                                            <span className="licon icon-dat"/>{date}
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                        <div className="gradient-overlay"/>
                        <div className="color-overlay"/>
                    </div>
                </div>
            }
            {window.innerWidth > 500 &&
                <div className="blog-card" style={background}>
                    <div className={"blog-card__container"}>
                        <div className="title-content">
                            {title ?
                                <h3>
                                    <a href={titleLink}>
                                        {title}
                                    </a>
                                </h3>
                                : null}
                            {intro ? <div className="intro"><a href={introLink}>{intro}</a></div> : null}
                        </div>
                        <div className="card-info">
                            {cardInfo}
                            {readMore ?
                                <div className={"carousel-button"}
                                     onClick={changeCarousel}
                                >
                                    {readMore}
                                    <span className="licon icon-arr icon-black"/>
                                </div> : null
                            }
                        </div>
                        <div className="utility-info">
                            <ul className="utility-list" key={`utility-list-${id}`}>
                                {date ?
                                    <li key={`date-list-${id}`}>
                                        <span className="licon icon-dat"/>{date}
                                    </li>
                                    : null}
                                {tag ? (
                                    <li key={`tag-list-${id}`}>
                                        <span className="licon icon-tag"/>
                                        {getTags()}
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                        <div className="gradient-overlay"/>
                        <div className="color-overlay"/>
                    </div>
                </div>

            }

        </Cards>
    )
}
