import React from "react";
import '../App.css';
import RecettesBody from "./recettes/RecettesBody";

export default function Recettes() {
    return (
        <>
            <RecettesBody/>
        </>
    );
}