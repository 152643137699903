import React from "react";
import '../../App.css';
import ContactBody from "../content/ContactBody";

export default function Contact() {
    return (
        <>
            <ContactBody/>
        </>
    );
}