import React from "react";
import roux from "./roux.json"
import RecettesCards from "../../../utils/RecettesCards";
import cuissonHomard from "./cuissonHomard.json";
import pateFraiches from "./patesFraiches.json";
import raviolis from "./raviolis.json";
import riccota from "./riccota.json";
import babeurre from "./babeurre.json";
import pateBrisee from "./pateBrisee.json";

export default function BasesEtTechniques() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Bases et techniques</h2>
            </div>
            <RecettesCards {...roux}/>
            <RecettesCards {...cuissonHomard}/>
            <RecettesCards {...pateFraiches}/>
            <RecettesCards {...raviolis}/>
            <RecettesCards {...riccota}/>
            <RecettesCards {...babeurre}/>
            <RecettesCards {...pateBrisee}/>
        </div>
    );
}