import React from "react";

export default function RecettesCards({
    nom,
    etapes,
    description,
    ingredients,
    notes
}) {
    return (
        <div className="container-body recettes-container mb-5 mt-5">
            <div className={'d-flex text-wrapper-center mb-3'}>
                <h1>{nom}</h1>
            </div>
            { description &&
                <div className="mb-3 d-flex justify-content-around">
                    {description.map(desc => {
                        return <div className="ms-4">{desc}</div>
                    })}
                </div>
            }
            { notes &&
                <div className="mb-1 d-flex row">
                    {notes.map(note => {
                        return <div>{note}</div>
                    })}
                </div>
            }
            <ul>
                {
                    ingredients.map(ingredient => {
                        return isNaN(ingredient.id) ?
                            <h6 className="mt-4"
                                key={ingredient.id}
                            >
                                {ingredient.nom}
                            </h6> :
                            <li key={ingredient.id}>
                                {ingredient.quantite} {ingredient.nom}
                            </li>
                        
                    })
                }
            </ul>
            <ol>
            {
                etapes.map(etape => {
                    return isNaN(etape.id) ? 
                    <h5 className="mb-2 mt-5"
                        key={etape.id}
                    >
                        {etape.contenu}
                    </h5> :
                    <li className="mb-2"
                        key={etape.id}>
                        {etape.contenu}
                    </li>
                })
            }
            </ol>
        </div>
    );
}