import React from "react";
import saladeBetteraveFenouillePommes from "./saladeBetteraveFenouillePommes.json"
import RecettesCards from "../../../utils/RecettesCards";

export default function Accompagnements() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Accompagnements</h2>
            </div>
            <RecettesCards {...saladeBetteraveFenouillePommes}/>
        </div>
    );
}