import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import muffinBleuets from "./muffinsAuxBleuets.json"
import guimauves from "./guimauves.json"
import biscuitsChocolatBlancMacadam from "./biscuitsChocolatBlancMacadam.json"
import painAuxBananesProteine from "./painAuxBananesProteine.json"

export default function Collations() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Collations</h2>
            </div>
            <RecettesCards {...muffinBleuets}/>
            <RecettesCards {...guimauves}/>
            <RecettesCards {...biscuitsChocolatBlancMacadam}/>
            <RecettesCards {...painAuxBananesProteine}/>
        </div>
    );
}