import React, {useContext, useState} from "react";

const WebsiteDataContext = React.createContext()

export const useWebsiteContext = () => {
    return useContext(WebsiteDataContext)
}

export const WebsiteDataProvider = ({children}) => {

    const [emailTemplate, setEmailTemplate] = useState({
        poste: "",
        salaire: "",
        periode: "heure",
        nom: "",
        telephone: "",
        courriel: "",
        message: "",
        entreprise: "",
        sujet: ""
    })

    const clearTemplate = () => {
        setEmailTemplate({
            poste: "",
            salaire: "",
            periode: "heure",
            nom: "",
            telephone: "",
            courriel: "",
            message: "",
            entreprise: "",
            sujet: ""
        })
    }

    const changeEmailTemplate = (key, value) => {
        setEmailTemplate({...emailTemplate, [key]: value})
    }

    const french = {
        language: 'french',
        homeBody: {
            title: "Voici une liste non exhaustive de mes plus récents travaux",
            cards: {
                card: {
                    edigo: "Collaboration sur l'outil Edigo.io",
                    portfolio: "Ce web portfolio lui-même!",
                    catDungeon: "Jeu vidéo programmé de zéro en Rust.",
                    photoshopDuPauvre: "Logiciel de traitement d'image avec MVC",
                    usines: "Laboratoire d'analyse de xml et GUI avec MVC et Strategy",
                    dictionnaire: "Laboratoire de lecture et écriture de fichiers système et GUI",
                },
                label: {
                    photoshopDuPauvre: "Traitement d'image",
                    usines: "Usines",
                    dictionnaire: "Dictionnaire",
                }
            }
        },
        footer: {
            mesProjets: "Mes projets",
            quiSuisJe: "Qui suis-je",
            maGalerie: "Ma galerie",
            contactezMoi: "Contactez-moi",
            artsVisuels: "Arts visuels"
        },
        global: {
            mesProjets: "Mes projets",
            projets: "Projets",
            profil: "Profil",
            galerie: "Galerie",
            contact: "Contact",
            portfolio: "Portfolio",
            aPropos: "À propos",
            deFrom: 'de',
            deOf: 'de',
            depuis: "Depuis",
            programmer: "Programmer",
            telephone: "Téléphone",
            salaire: "Salaire",
            enDeveloppement: "Certaines fonctionnalités sont désactivées, car le site est présentement en développement. Merci de votre compréhension."
        },
        aProposBody: {
            credit: "Toutes les ressources utilisés pour la création de ce site-portfolio sont des\n" +
                "ressources gratuites et libre de droits",
            creditSub: "Je tiens simplement à ce que tout les auteurs soient référencés",
            creditThanks: "Merci à vous.",
            icon: "Icône de l'onglet fait par",
            backgroundVideo: "Video d'arrière plan par",
            websiteTemplate: "Modèle du site par",
        },
        contact: {
            sendMail: 'Embauchez-moi',
            appel: "Si vous choisissez de m'appeler, nous discuteront en premier lieu des points ci-dessous :",
            salaire: "Salaire ;",
            taches: "Tâches ;",
            type: "Type d'emploi ;",
            entreprise: "Entreprise.",
            pasInteresse: "Je ne suis pas particulièrement intéressé par le travail sur la route et le développement" +
                " web, particulièrement le front-end, et le développement d'applications mobile.",
            essayez: "Si l'offre est pour effectuer les tâches ci-dessus, proposez le moi, " +
                "peut-être arriverez-vous à me convaincre.",
            interret: "Je préfère un emploi en télétravail, un peu plus orienté développement " +
                "d'applications ou en réseautique.",
            devopsOuFinancement: "Le DevOps m'intéresse aussi et je suis ouverts aux offres du type: " +
                "nous financeront tes cours d'université si tu viens travailler pour nous après pendant une durée déterminée.",
        },
        profil: {
            programmeur: "Programmeur",
            administrateurReseau: "Administrateur réseau",
            formation: "Formation",
            baccalaureatTechno: "Baccalauréat en génie des technologies de l'information",
            stage: "stage",
            experiencePro: "Expérience professionnelle",
            dep: "Diplôme d’études professionnel en soutien informatique",
            dec: "Diplôme d’études collégial en gestion de réseaux informatiques",
            technicienReseau: "Technicien réseau",
            competenceParti: "Compétences particulières",
            techniques: "Techniques",
            informatiques: "Informatiques",
            programmation: "programmation",
            langagesProg: "Lagages de programmation",
            librairies: "Librairies",
            reseau: "Réseau",
            virtualisation: "Virtualisation",
            serveurs: "Serveurs",

            objectifsProfessionnels: {
                titre: "Objectifs professionnels",
                description: [
                    "Lorsque j'aurais terminé mes études en génie, j’aimerais possiblement orienter ma carrière vers des domaines en technologies.",
                    "J’aimerais en apprendre davantage sur DevOps ou l’intelligence artificielle.",
                    "Ultimement, je voudrais un emploi me permettant d’innover en développement durable et en environnement."
                ]
            },
            auxEtudes: "Présentement, je suis un étudiant au baccalauréat en génie des technologies",
            competences: {
                webisoft: [
                    "Concevoir des applications",
                    "Faire des requêtes sur des serveurs externes",
                    "Traiter des données externes",
                    "Assurer la qualité des produits",
                    "Intégrer une inteface Web",
                    "Corriger des erreurs de logiciels",
                    "Mettre à jour un système",
                    "Concevoir une interface utilisateur"
                ],
                mediavore: [
                    "Restructurer des installations réseau pour mettre en place un système de redondance",
                    "Évaluer l’inventaire matériel",
                    "Gérer des bases de données phpMyAdmin et SQL",
                    "Gérer des serveur et des données",
                    "Améliorer de la sécurité interne du réseau"
                ],
                cogeco: [
                    "Installer les équipements réseau pour les écrans publicitaires et d’heures de train du métro \n" +
                    "de Montréal",
                    "Entretenir les équipements réseau des écrans publicitaires",
                    "Monitorer le fonctionnement de l’équipement réseau",
                    "Solutionner les problèmes liés à l’équipement réseau"
                ],
                techniques: [
                    "Appliquer une démarche algorithmique",
                    "Appliquer une démarche orientée objet",
                    "Structurer des données",
                    "Appliquer les différents concepts de programmation",
                    "User de créativité pour trouver de nouvelles solutions et innover",
                    "Analyser l’architecture d’un réseau informatique",
                    "Implanter des technologies et les services propres au réseau Internet",
                    "Assurer la gestion du réseau informatique",
                    "Implanter un réseau informatique",
                    "Développer des utilitaires",
                    "Assurer l’évolution du réseau informatique"
                ]
            }
        },
        galerie: {
            imageNotFound: "L'image n'a pas été trouvée",
            catDungeon: {
                title: "Cat Dungeon",
                intro: "Jeu programmé en Rust",
                cardInfo: "Une collaboration avec Laflèche Chevrette",
                readMore: "Galerie",
                date: "2021",
                tag: ["Cat Dungeon", "Collaboration"]
            },
            edigo: {
                title: "Edigo",
                intro: "Éditeur d'image Edigo",
                cardInfo: "Un stage chez Webisoft",
                readMore: "Galerie",
                date: "2021",
                tag: ["Edigo", "Collaboration"]
            },
            portfolio: {
                title: "Portfolio",
                intro: "Portfolio React",
                cardInfo: "Portfolio hébergé avec firebase programmé avec JSX 17",
                readMore: "Galerie",
                date: "2021",
                tag: ["Portfolio", "Curriculum"]
            },
            photoshopDuPauvre: {
                title: "Éditeur d'images",
                intro: "Projet d'études à l'ÉTS programmé en Java",
                cardInfo: "Une collaboration avec Laflèche Chevrette, Evens Guillaume et Émile Brunelle",
                readMore: "Galerie",
                date: "2020",
                tag: ["Éditeur d'images", "Études", "Collaboration"]
            },
            usines: {
                title: "Simulateur d'usines",
                intro: "Projet d'études à l'ÉTS programmé en Java",
                cardInfo: "Laboratoire de programmation à l'ÉTS programmer en Java",
                readMore: "Galerie",
                date: "2020",
                tag: ["Usines", "Études"]
            },
            dictionnaire: {
                title: "Dictionnaire",
                intro: "Projet d'études à l'ÉTS programmé en Java",
                cardInfo: "Une collaboration avec Laflèche Chevrette",
                readMore: "Galerie",
                date: "2020",
                tag: ["Dictionnaire", "Études", "Arbre binaire", "Collaboration"]
            }
        },
        modalContact: {
            titre: "Envoyez-moi une offre d'emploi",
            sousTitre: "Remplissez le formulaire ci-dessous",
            champInvalide: "Ce champ ne peut pas être vide !",
            input: {
                nom: "Nom complet",
                mail: "Adresse courriel",
                entreprise: "Entreprise",

            },
            select: {
                salaire: [
                    "Heure",
                    "Hebdomadaire",
                    "Annuel",
                    "Contrat"
                ],
                position: [
                    "DevOps",
                    "Architechte réseau",
                    "Technicien réseau",
                    "Développeur d'application",
                    "Enseignant",
                    "Administrateur réseau",
                    "Technicien en télécommunication",
                    "Programmeur analyste",
                    "Programmeur locigiel",
                    "Programmeur applicatif",
                    "Développeur web junior",
                    "Développeur web senior",
                    "Gestionnaire de projet",
                    "Autre"
                ]
            },
            send: "Envoyer",
            facultatif: "Facultatif",
            error: "Veuillez remplir tout les champs non facultatifs ci-dessus !",
            success: "Le message à été envoyé avec succès !"
        }
    }

    const english = {
        language: 'english',
        homeBody: {
            title: "Here is a non exhaustive list of my most recent works",
            cards: {
                card: {
                    edigo: "Collaboration on the tool Edigo.io",
                    portfolio: "This web-folio itself!",
                    catDungeon: "Video game programmed from scratch in Rust.",
                    photoshopDuPauvre: "Image processing software with MVC",
                    usines: "XML and GUI analysis lab with MVC and Strategy patterns",
                    dictionnaire: "Laboratory for reading and writing system and GUI files",
                },
                label: {
                    photoshopDuPauvre: "Image processing",
                    usines: "Factory",
                    dictionnaire: "Dictionary",
                }
            }
        },
        footer: {
            mesProjets: "My projects",
            quiSuisJe: "Who am I",
            maGalerie: "My gallery",
            contactezMoi: "Contact me",
            artsVisuels: "Visual Arts"
        },
        global: {
            mesProjets: "My projects",
            projets: "Projects",
            profil: "Profile",
            galerie: "Gallery",
            contact: "Contact",
            portfolio: "Portfolio",
            aPropos: "About",
            deFrom: 'from',
            deOf: 'of',
            depuis: "Since",
            programmer: "Program",
            telephone: "Phone",
            salaire: "Salary",
            enDeveloppement: "Some features are disabled as the site is currently in development. Thank you for your understanding."
        },
        aProposBody: {
            credit: "All the resources used for the creation of this portfolio site are free and royalty-free resources",
            creditSub: "I just want all authors to be referenced",
            creditThanks: "Thank you.",
            icon: "Tab icon made by",
            backgroundVideo: "Background video by",
            websiteTemplate: "Site template by",
        },
        contact: {
            sendMail: 'Hire me',
            appel: "If you choose to call me, we will first discuss the points below :",
            salaire: "Salary ;",
            taches: "Tasks ;",
            type: "Type of employment ;",
            entreprise: "Company.",
            pasInteresse: "I'm not particularly interested in working on the road and web development, especially front-end, and mobile app development.",
            essayez: "If the offer is to perform the above tasks, offer it to me, maybe you will be able to convince me.",
            interret: "I would prefer to work from home, a little more oriented application development or networking.",
            devopsOuFinancement: "I am also interested in DevOps and open to offers such as: " +
                "we will finance your university courses if you come to work for us afterwards for a fixed period of time.",
        },
        profil: {
            programmeur: "Programmer",
            administrateurReseau: "Network Administrator",
            formation: "Formation",
            baccalaureatTechno: "Bachelor of Information Technology Engineering",
            stage: "internship",
            experiencePro: "Professional experience",
            dep: "Professional degree in computer support",
            dec: "College degree in network management",
            technicienReseau: "Network technician",
            competenceParti: "Special skills",
            techniques: "Technical",
            informatiques: "Computer",
            programmation: "programming",
            langagesProg: "Programming language ",
            librairies: "Programming libraries",
            reseau: "Network",
            virtualisation: "Virtualization",
            serveurs: "Servers",
            objectifsProfessionnels: {
                titre: "Professional objectives",
                description: [
                    "Once I have completed my engineering studies, I may want to focus my career on technology fields.",
                    "I would like to learn more about DevOps or artificial intelligence.",
                    "Ultimately, I would like a job that allows me to innovate in sustainable development and the environment."
                ]
            },
            auxEtudes: "Currently, I am a Bachelor of Technology Engineering student",
            competences: {
                webisoft: [
                    "Design applications",
                    "Make queries on external servers",
                    "Process external data",
                    "Ensuring product quality",
                    "Integrate a web inteface",
                    "Fix software errors",
                    "Update a system",
                    "Design a user interface"
                ],
                mediavore: [
                    "Restructure network facilities to implement a redundancy system",
                    "Evaluate the material inventory",
                    "Manage phpMyAdmin and SQL databases",
                    "Manage servers and data",
                    "Improve internal network security"
                ],
                cogeco: [
                    "Install network equipment for advertising screens and train hours of the Montreal metro",
                    "Maintain the network equipment of advertising screens",
                    "Monitor the operation of network equipment",
                    "Troubleshoot network equipment issues"
                ],
                techniques: [
                    "Apply an algorithmic approach",
                    "Apply an object-oriented approach",
                    "Structuring data",
                    "Apply the different programming concepts",
                    "Use creativity to find new solutions and innovate",
                    "Analyze the architecture of a computer network",
                    "Implement technologies and services specific to the Internet",
                    "Ensure the management of the computer network",
                    "Implement a computer network",
                    "Develop utilities",
                    "Ensuring the evolution of the computer network"
                ]
            }
        },
        galerie: {
            imageNotFound: "Image not found",
            catDungeon: {
                title: "Cat Dungeon",
                intro: "Game programmed with Rust",
                cardInfo: "A collaboration with Laflèche Chevrette",
                readMore: "Gallery",
                date: "2021",
                tag: ["Cat Dungeon", "Collaboration"]
            },
            edigo: {
                title: "Edigo",
                intro: "Edigo Image Editor",
                cardInfo: "An internship at Webisoft",
                readMore: "Gallery",
                date: "2021",
                tag: ["Edigo", "Collaboration"]
            },
            portfolio: {
                title: "Portfolio",
                intro: "Portfolio React",
                cardInfo: "Hosted portfolio with firebase programmed with JSX 17",
                readMore: "Gallery",
                date: "2021",
                tag: ["Portfolio", "Curriculum"]
            },
            photoshopDuPauvre: {
                title: "Image editor",
                intro: "Study project at ÉTS programmed with Java",
                cardInfo: "A collaboration with Laflèche Chevrette, Evens Guillaume and Émile Brunelle",
                readMore: "Gallery",
                date: "2020",
                tag: ["Image editor", "Studies", "Collaboration"]
            },
            usines: {
                title: "Factory simulator",
                intro: "Study project at ÉTS programmed with Java",
                cardInfo: "Programming laboratory at ÉTS programming with Java",
                readMore: "Gallery",
                date: "2020",
                tag: ["Factory", "Studies"]
            },
            dictionnaire: {
                title: "Dictionary",
                intro: "Study project at ÉTS programmed with Java",
                cardInfo: "A collaboration with Laflèche Chevrette",
                readMore: "Gallery",
                date: "2020",
                tag: ["Dictionary", "Studies", "Binary Tree", "Collaboration"]
            }
        },
        modalContact: {
            titre: "Send me a job offer",
            sousTitre: "Fill out the form below",
            champInvalide: "This field cannot be empty !",
            input: {
                nom: "Full name",
                mail: "Email address",
                entreprise: "Enterprise",

            },
            select: {
                salaire: [
                    "Hour",
                    "Weekly",
                    "Annual",
                    "Contract"
                ],
                position: [
                    "DevOps",
                    "Architechte network",
                    "Network Technician",
                    "Application Developer",
                    "Teacher",
                    "Network Administrator",
                    "Telecommunications Technician",
                    "Programmer Analyst",
                    "Locigial programmer",
                    "Application Programmer",
                    "Junior Web Developer",
                    "Senior Web Developer",
                    "Project Manager",
                    "Other"
                ]
            },
            send: "Send",
            facultatif: "Facultative",
            error: "Please complete all non-optional fields above !",
            success: "The message was sent successfully !"
        }
    }

    const [language, useLanguage] = useState(french)

    const useEnglish = () => {
        useLanguage(english)
    }

    const useFrench = () => {
        useLanguage(french)
    }

    const [carousel, setCarousel] = useState([])

    return (
        <WebsiteDataContext.Provider value={{
            useFrench,
            useEnglish,
            language,
            changeEmailTemplate,
            emailTemplate,
            clearTemplate,
            carousel,
            setCarousel
        }}>
            {children}
        </WebsiteDataContext.Provider>
    )
}