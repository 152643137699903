import React from "react";
import '../../App.css';
import '../css/VideoSection.css';
import { useWebsiteContext } from "../../WebsiteDataContext";

function VideoSection() {
    const { language } = useWebsiteContext()
    return (
        <div className='video-container'>
            <video src='/videos/neon.mp4' autoPlay loop muted/>
            <h1>{language.global.mesProjets}</h1>
            <h4>{language.global.enDeveloppement}</h4>
        </div>
    );
}

export default VideoSection;