import React, {useState} from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from "file-saver";
import '../../css/Body.css';
import { useWebsiteContext } from "../../../WebsiteDataContext";

export default function CurriculumVitae({lang}) {
    const { language } = useWebsiteContext();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    }

    const cv = lang === "french" ?
        "/cv/cv-fr.pdf" :
        "/cv/cv-en.pdf"

    const saveFile = () => {
        saveAs(
            cv,
            "Curriculum Vitae - JeanPhilippe Mongeau"
        )
    }

    const [changeScaling, setChangeScaling] = useState(false)

    const resizePdf = () => {
        if (!changeScaling && window.innerWidth <= 820) {
            setChangeScaling(true)
        } else if (changeScaling && window.innerWidth > 820) {
            setChangeScaling(false)
        }
    }

    window.addEventListener('resize', resizePdf)

    return (
        <div className="d-flex justify-content-center row">
            <div className="d-flex row-1 w-50" style={{color: "#858dfc"}}>
                <div className="col-5"/>
                <div className="d-flex justify-content-center col-1" style={{minWidth: 150}}>
                    <i className="fas fa-angle-left pdf-arrow mt-1" onClick={() => setPageNumber(1)}/>
                    <p className="ms-3 me-3">Page {pageNumber} {language.global.deOf} {numPages}</p>
                    <i className="fas fa-angle-right pdf-arrow mt-1" onClick={() => setPageNumber(2)}/>
                </div>
                <div className="ms-3 curriculum-arrows">
                    <i className="fa fa-download clickable" aria-hidden="true" onClick={saveFile}/>
                </div>
            </div>
                <div className="d-flex justify-content-center row-1 clickable" onClick={saveFile}>
                    <Document
                        renderMode="canvas"
                        file={ cv }
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {!changeScaling &&
                            <Page size="A4" pageNumber={pageNumber}/>
                        }
                        {changeScaling &&
                            <Page size="A4" pageNumber={pageNumber} scale={0.75}/>
                        }
                    </Document>
                </div>
        </div>
    )
}