import React from "react";
import '../../App.css';
import GalerieBody from "../content/GalerieBody";

export default function Galerie() {
    return (
        <>
            <GalerieBody/>
        </>
    );
}