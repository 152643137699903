import React from "react";
import '../../App.css';
import VideoSection from "../utils/VideoSection";
import HomeBody from "../content/HomeBody";

function Home() {
    return (
        <>
            <VideoSection/>
            <HomeBody/>
        </>
    );
}

export default Home;