import React from "react";
import { Link } from "react-scroll";
import './css/Footer.css';
import {useWebsiteContext} from "../WebsiteDataContext";

function Footer() {

    const {language} = useWebsiteContext()

    return (
        <>
            <div className='footer-container'>
                <div className='footer-links'>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>{language.footer.mesProjets}</h2>
                            <Link activeClass="active" to="home" spy={true} smooth={true}>{language.global.projets}</Link>
                        </div>
                        <div className='footer-link-items'>
                            <h2>{language.footer.quiSuisJe}</h2>
                            <Link to="profil" spy={true} smooth={true}>{language.global.profil}</Link>
                        </div>
                    </div>

                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>{language.footer.maGalerie}</h2>
                            <Link to="galerie" spy={true} smooth={true}>{language.global.galerie}</Link>
                        </div>
                        <div className='footer-link-items'>
                            <h2>{language.footer.contactezMoi}</h2>
                            <Link to="contact" spy={true} smooth={true}>{language.global.contact}</Link>
                        </div>
                        <div className='footer-link-items'>
                            <h2>{language.footer.artsVisuels}</h2>
                            <Link to="apropos" spy={true} smooth={true}>{language.global.aPropos}</Link>
                        </div>
                    </div>
                </div>

                <section className='msocial'>
                    <div className='msocialwrap'>
                        <div className='flogo'>
                            <Link to='home' className='logosocialm'>
                                <i className="fas fa-user-circle"/> {language.global.portfolio}
                            </Link>
                        </div>
                        <small className='webrights'>Portfolio © 2022</small>
                        <div className='linkedin'>
                            <a
                                href="https://www.linkedin.com/in/jp-mongeau/"
                                className='linkedin-text'
                            >
                                LinkedIn <i className="fab fa-linkedin"/>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
            <div className="mobile-footer-tweak">

            </div>
        </>
    );
}

export default Footer;