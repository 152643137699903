import React, { useState } from "react";
import "../css/Button.css";
import Accompagnements from "./data/accompagnements/Accompagnements";
import BasesEtTechniques from "./data/bases/BasesEtTechniques";
import Breuvages from "./data/breuvages/Breuvages";
import Condiments from "./data/condiments/Condiments";
import Dejeuner from "./data/dejeuner/Dejeuner";
import Desserts from "./data/desserts/Desserts";
import Repas from "./data/repas/Repas";
import Collations from "./data/collations/Collations";
import Assaisonnements from "./data/assaisonnements/Assaisonnements";

export default function RecettesBody() {

    const [toggle, setToggle] = useState(false)
    const [breakfast, setBreakfast] = useState(true)
    const [meals, setMeals] = useState(true)
    const [desserts, setDesserts] = useState(true)
    const [collations, setCollation] = useState(true)
    const [drink, setDrink] = useState(true)
    const [sides, setSides] = useState(true)
    const [condiments, setCondiments] = useState(true)
    const [bases, setBases] = useState(true)
    const [seasonning, setSeasonning] = useState(true)

    const toggleBreakfast = () => {
        if (toggle && breakfast) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setBreakfast((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleMeals = () => {
        if (toggle && meals) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setMeals((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleDesserts = () => {
        if (toggle && desserts) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setDesserts((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleCollations = () => {
        if (toggle && collations) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setCollation((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleDrink = () => {
        if (toggle && drink) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setDrink((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleSides = () => {
        if (toggle && sides) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setSides((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleCondiments = () => {
        if (toggle && condiments) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setCondiments((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleBases = () => {
        if (toggle && bases) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setBases((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleSeasoning = () => {
        if (toggle && seasonning) {
            toggleAllOn()
            setToggle(false)
        } else {
            toggleAllOff()
            setSeasonning((prevState) => !prevState)
            setToggle(true)
        }
    }

    const toggleAllOff = () => {
        setBreakfast(false)
        setBases(false)
        setDrink(false)
        setSides(false)
        setCondiments(false)
        setDesserts(false)
        setMeals(false)
        setCollation(false)
        setSeasonning(false)
    }

    const toggleAllOn = () => {
        setBreakfast(true)
        setBases(true)
        setDrink(true)
        setSides(true)
        setCondiments(true)
        setDesserts(true)
        setMeals(true)
        setCollation(true)
        setSeasonning(true)
    }

    return (
        <div className='body-recettes'>
            <div className={'d-flex container-body row'}>
                <div className="d-flex recettes-margin-mobile row">
                    <div className="col">
                        { !(breakfast && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleBreakfast}
                            >
                                Déjeuner
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleBreakfast}
                            >
                                Déjeuner
                            </div>
                        }
                        { !(meals && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleMeals}
                            >
                                Plat principal
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleMeals}
                            >
                                Plat principal
                            </div>
                        }
                        { !(desserts && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleDesserts}
                            >
                                Desserts
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleDesserts}
                            >
                                Desserts
                            </div>
                        }
                    </div>
                    <div className="col">
                        { !(drink && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleDrink}
                            >
                                Breuvages
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleDrink}
                            >
                                Breuvages
                            </div>
                        }
                        { !(collations && toggle) ?
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleCollations}
                            >
                                Collations
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleCollations}
                            >
                                Collations
                            </div>
                        }
                        { !(sides && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleSides}
                            >
                                Accompagnements
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleSides}
                            >
                                Accompagnements
                            </div>
                        }
                    </div>
                    <div className="col">
                        { !(seasonning && toggle) ?
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleSeasoning}
                            >
                                Assaisonnements
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleSeasoning}
                            >
                                Assaisonnements
                            </div>
                        }
                        { !(condiments && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleCondiments}
                            >
                                Condiments
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleCondiments}
                            >
                                Condiments
                            </div>
                        }
                        { !(bases && toggle) ? 
                            <div className="btn btn--outline mt-1 col-12"
                                onClick={toggleBases}
                            >
                                Bases
                            </div> :
                            <div className="btn btn--outline--down mt-1 col-12"
                                onClick={toggleBases}
                            >
                                Bases
                            </div>
                        }
                    </div>
                </div>
                { breakfast && <Dejeuner/> }
                { meals && <Repas/> }
                { desserts && <Desserts/> }
                { collations && <Collations/> }
                { sides && <Accompagnements/> }
                { condiments && <Condiments/> }
                { drink && <Breuvages/> }
                { seasonning && <Assaisonnements/> }
                { bases && <BasesEtTechniques/> }
            </div>
        </div>
    );
}