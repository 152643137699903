import React from "react";
import RecettesCards from "../../../utils/RecettesCards";
import crepe from "./crepes.json";
import bagels from "./bagels.json";
import crepesSarrasin from "./crepesSarrasin.json";

export default function Dejeuner() {

    return (
        <div className="mt-5 recettes-margin-mobile">
            <div className="container-body">
                <h2>Déjeuner</h2>
            </div>
            <RecettesCards {...crepe}/>
            <RecettesCards {...bagels}/>
            <RecettesCards {...crepesSarrasin}/>
        </div>
    );
}