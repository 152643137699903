import React, {useState} from "react";
import '../css/Body.css';
import {useWebsiteContext} from "../../WebsiteDataContext";
import {SmallSeparator} from "../styled/Separator";
import SpecialCards from "../utils/SpecialCards";
import CarouselGalerie from "./components/CarouselGalerie";

function GalerieBody() {
    const {language, carousel} = useWebsiteContext()

    const [displayCarousel, setDisplayCarousel] = useState(false)
    const toggleOn = () => {
        setDisplayCarousel(true)
    }

    const toggle = () => {
        setDisplayCarousel((prevState) => !prevState)
    }

    const carouselPackage = {toggleOn, toggle}

    const galerie = {
        catDungeon: [],
        edigo: [],
        portfolio: [],
        photoshopDuPauvre: [],
        usines: [],
        dictionnaire: []
    }

    return (
        <div className='body'>
            <div className={"text-margin-large container-body"}>
                <div className={"text-wrapper-center"}><h1>{language.global.galerie}</h1></div>
                <SmallSeparator/>
                <div className={"text-margin-small"}/>

                <div className={"blog-card__container row"}>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={1}
                                      title={language.galerie.catDungeon.title}
                                      intro={language.galerie.catDungeon.intro}
                                      cardInfo={language.galerie.catDungeon.cardInfo}
                                      imgList={galerie.catDungeon}
                                      readMore={language.galerie.catDungeon.readMore}
                                      date={language.galerie.catDungeon.date}
                                      tag={language.galerie.catDungeon.tag}
                                      backgroundUrl={'/images/2021.png'}
                                      {...carouselPackage}
                        />
                    </div>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={2}
                                      title={language.galerie.edigo.title}
                                      intro={language.galerie.edigo.intro}
                                      cardInfo={language.galerie.edigo.cardInfo}
                                      imgList={galerie.edigo}
                                      readMore={language.galerie.edigo.readMore}
                                      date={language.galerie.edigo.date}
                                      tag={language.galerie.edigo.tag}
                                      backgroundUrl={'/images/edigo.png'}
                                      {...carouselPackage}
                        />
                    </div>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={3}
                                      title={language.galerie.portfolio.title}
                                      intro={language.galerie.portfolio.intro}
                                      cardInfo={language.galerie.portfolio.cardInfo}
                                      imgList={galerie.portfolio}
                                      readMore={language.galerie.portfolio.readMore}
                                      date={language.galerie.portfolio.date}
                                      tag={language.galerie.portfolio.tag}
                                      backgroundUrl={'/images/web-portfolio-reactjs.png'}
                                      {...carouselPackage}
                        />
                    </div>
                </div>

                <div className={"blog-card__container row"}>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={1}
                                      title={language.galerie.photoshopDuPauvre.title}
                                      intro={language.galerie.photoshopDuPauvre.intro}
                                      cardInfo={language.galerie.photoshopDuPauvre.cardInfo}
                                      imgList={galerie.photoshopDuPauvre}
                                      readMore={language.galerie.photoshopDuPauvre.readMore}
                                      date={language.galerie.photoshopDuPauvre.date}
                                      tag={language.galerie.photoshopDuPauvre.tag}
                                      backgroundUrl={'/images/photoshopdupauvre.jpg'}
                                      {...carouselPackage}
                        />
                    </div>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={2}
                                      title={language.galerie.usines.title}
                                      intro={language.galerie.usines.intro}
                                      cardInfo={language.galerie.usines.cardInfo}
                                      imgList={galerie.usines}
                                      readMore={language.galerie.usines.readMore}
                                      date={language.galerie.usines.date}
                                      tag={language.galerie.usines.tag}
                                      backgroundUrl={'/images/simulationusine.png'}
                                      {...carouselPackage}
                        />
                    </div>
                    <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-4"}>
                        <SpecialCards id={3}
                                      title={language.galerie.dictionnaire.title}
                                      intro={language.galerie.dictionnaire.intro}
                                      cardInfo={language.galerie.dictionnaire.cardInfo}
                                      imgList={galerie.dictionnaire}
                                      readMore={language.galerie.dictionnaire.readMore}
                                      date={language.galerie.dictionnaire.date}
                                      tag={language.galerie.dictionnaire.tag}
                                      backgroundUrl={'/images/dico.png'}
                                      {...carouselPackage}
                        />
                    </div>
                </div>


                {carousel.length > 0 &&
                    <div id="carousel-gallery"
                         className={
                             displayCarousel ?
                                 "contact-phone-container d-flex justify-content-center row mt-5 carousel-gallery" :
                                 "d-flex justify-content-center row mt-5 carousel-gallery"
                         }
                    >
                        <div className={"d-flex justify-content-end"} style={{width: "90%"}}>
                            <i className={displayCarousel ? "fa fa-angle-down clickable fa-2x mt-5" : ""}
                               aria-hidden="true" onClick={toggle}/>
                        </div>

                        {carousel && displayCarousel &&
                            <div className="d-flex row justify-content-center">
                                <CarouselGalerie/>
                            </div>
                        }
                    </div>
                }

            </div>
        </div>
    )
}

export default GalerieBody;