import React from "react";
import '../css/Apropos.css';
import {useWebsiteContext} from "../../WebsiteDataContext";
import {SmallSeparator} from "../styled/Separator";

function AproposBody() {

    const {language} = useWebsiteContext()

    return (
        <div className='body'>
            <div className="apropos-container container-body">
                <div className={"text-wrapper-center text-margin-small"}>
                    <h1>{language.global.aPropos}</h1>
                    <SmallSeparator/>
                </div>
                <div className='credits-to-authors'>
                    <div className={"text-wrapper-center text-margin-small"}>
                        <h2>{language.aProposBody.credit}</h2>
                    </div>
                    <div className={"text-wrapper-center text-margin-small"}>
                        <h3>{language.aProposBody.creditSub}</h3>
                    </div>
                    <div className={"text-wrapper-center text-margin-small thanks-padding"}>
                        <h4>{language.aProposBody.creditThanks}</h4>
                    </div>
                    <div>
                        <li className='credit-items'>
                            <span>{language.aProposBody.icon} </span>
                            <a href="https://www.freepik.com" title="Freepik">
                                Freepik
                            </a>
                            <span> {language.global.deFrom} </span>
                            <a href="https://www.flaticon.com/" title="Flaticon">
                                www.flaticon.com
                            </a>
                        </li>

                        <li className='credit-items'>
                            <span>{language.aProposBody.backgroundVideo} </span>
                            <a href="https://pixabay.com/fr/users/gr8effect-66428/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=21368">
                                Dimitris Christou
                            </a>
                            <span> {language.global.deFrom} </span>
                            <a href="https://pixabay.com/fr/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=21368">
                                Pixabay
                            </a>
                        </li>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AproposBody;